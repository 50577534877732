<template>
<div id="page-tipos-precios">
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height>
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="Administrar Formas de Pago" class="elevation-1 px-5 py-3">
          <v-card-actions>
            <v-spacer></v-spacer>
           <!--  <v-btn fab dark x-small color="secondary" slot="activator" @click.native="openModal(1)" title="Agregar Registro" v-tippy>
              <v-icon dark>add</v-icon>
            </v-btn> -->
          </v-card-actions>

          <v-card-text>
            <div id="datagrid">

              <v-client-table ref="vuetable" :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>

                <template slot="actions" slot-scope="props">
                  <v-btn x-small fab dark color="blue" @click.native="getRegistro(props.row)" title="Editar Registro" v-tippy>
                      <v-icon>edit</v-icon>
                    </v-btn>
                </template>
              </v-client-table>
            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modal" max-width="500px">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>{{ update ? "Actualizar" : "Nuevo" }} Forma de Pago</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md @keyup.enter="update ? updateRegistro() : saveRegistro()">
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-text-field label="Nombre" readonly v-model="model.nombre" :rules="[rules.required]" v-on:keydown.enter.prevent='1'></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-switch v-model="model.estatus" :label="model.estatus" true-value="Activo" false-value="Inactivo"></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-text-field label="Porcentaje de Comisión" v-model="model.comision" :rules="[validaCantidadFloat]"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal = false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        <v-btn color="success" @click.native="update ? updateRegistro() : saveRegistro()">
          <v-icon>done</v-icon> {{ update ? "Actualizar" : "Guardar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
export default {
  mounted: function() {
    if (!this.verificaPermiso('t.utilerias.formas.pago')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    this.getFormasPago();
  },
  data() {
    return {
      id_configuracion: "",
      fab: [],
      name: "datagrid",
      columns: [
        "nombre",
        "estatus",
        "comision",
        "actions"
      ],
      show_loading: true,
      options: {
        perPage: 10,
        pagination: {
          show: true
        },
        filterable: ["nombre", "estatus", "comision"],
        sortable: ["nombre", "estatus", "comision"],

        headings: {
          nombre: "Nombre",
          estatus: "Estatus",
          comision: "Porcentaje de Comisión",
          actions: "Operaciones"
        },
        listColumns:{
        estatus: [{
                    id: 'Activo',
                    text: "Activo"
                },
                {
                    id: 'Inactivo',
                    text: "Inactivo"
                }
            ]
        },
      },
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Utilerías",
          disabled: true,
          href: ""
        },
        {
          text: "Formas de Pago",
          disabled: true,
          href: ""
        }
      ],

      modal: false,

      update: false,

      model: {
        nombre: "",
        estatus: "Activo",
      },
      rules: {
        required: v => !!v || "Este campo es requerido",
        entero: function(numero) {
          const pattern = /^[\d]*$/;
          return pattern.test(numero) || "Debe ser un número entero";
        },

      },
      search: "",
      registros: {
        selected: [],
        items: []
      },

    };
  },
  methods: {
    validaCantidadFloat: function(value) {
      const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
      if (value != null && !pattern.test(value)) {
        return "Cantidad no válida"
      } else
        return true;

    },
    openModal: function(type) {
      if (type === 1) {
        this.update = false;
        this.model.nombre = "";
        this.model.uuid = "";
        this.model.comision = "";

      } else {
        this.update = true;
      }

      this.modal = true;
    },
    getFormasPago: function() {
      window.dialogLoader.show('Espere un momento por favor..');
      let data = {'selector': {
                    'type': 'sat_formas_pago'
                    },                    
                }

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0){
              response.data.docs.sort(this.ordenar_clave);
              this.registros.items = response.data.docs;
          }else
              this.registros.items = [];

          window.dialogLoader.hide();
        })
        .catch(error => {
          console.log(error)
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los registros.",
            footer: ""
          });
          window.dialogLoader.hide();
        });
    },
    ordenar_clave: function(a, b) {
            if (a.clave.toLowerCase() < b.clave.toLowerCase()) {
                return -1;
            }
            if (a.clave.toLowerCase() > b.clave.toLowerCase()) {
                return 1;
            }
            return 0;
        },
    getRegistro: function(item) {
      this.model = item;
      this.openModal(2);
    },

    updateRegistro: function() {
      if (this.$refs.form.validate()) {
        window.dialogLoader.show('Espere un momento por favor..');

        let data = this.model;

        window.axios
          .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', data)
          .then(response => {
            if (response.data.ok == true) {
              this.modal = false;
              this.getFormasPago();
              window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
                color: 'success'
              });
            } else {
              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: response.data,
                footer: ""
              });
            }
          })
          .catch(error => {
            window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..', {
              color: 'error'
            });
          }).then(() => {
            this.modal = false;
            window.dialogLoader.hide();
          });
      }
    },


  }
};
</script>
